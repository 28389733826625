<template>
  <b-card
    id="tabs-component"
    class="card-custom card-stretch gutter-b"
    :style="{ marginBottom: '25px' }"
    no-body
  >
    <b-tabs
      v-model="tabValue"
      card
      pills
      lazy
      nav-class="nav-light-success nav-bold"
      nav-wrapper-class="card-toolbar row pt-2 pb-8"
      align="left"
      content-class="card-body text-left"
      @activate-tab="
        ted = {};
        youtube = {};
        vimeo = {};
        iframe = {};
        html = {};
        ownVideo = {};
      "
    >
      <template v-slot:tabs-start>
        <div class="card-title">
          <h3 class="card-label">
            <div :class="`archetype arch-${familyType}`" v-if="archetypeSvg">
              <img :src="`data:image/svg+xml;base64,${archetypeSvg}`" alt="" />
            </div>
            <span v-html="title" />
          </h3>
        </div>
      </template>
      <template v-slot:tabs-end v-if="info">
        <div class="nav-item">
          <b-icon
            icon="info-circle-fill"
            scale="1.5"
            variant="warning"
            style="position: absolute; right: 1rem; top: 1rem"
            id="tabs-info"
          ></b-icon>
          <b-tooltip
            target="#tabs-info"
            :title="$t('profile.tabs.info')"
            variant="dark"
          />
        </div>
      </template>
      <b-tab
        no-body
        lazy
        v-for="(tab, index) in tabs"
        :key="tab.key"
        :title-item-class="index >= chunkAt ? 'd-none' : ''"
      >
        <template v-slot:title v-if="index < chunkAt">
          <span class="nav-icon">
            <i :class="tab.icon"></i>
          </span>
          <span class="nav-text" v-html="tab.title" />
        </template>
        <span
          v-if="object[tab.key] && object[tab.key].text"
          v-html="object[tab.key].text"
        />
        <div
          class="assets mt-8"
          v-if="
            object[tab.key] &&
              object[tab.key].assets &&
              object[tab.key].assets.length > 0
          "
        >
          <b-button
            tag="a"
            class="mr-4 mb-4 bg-gray-100 font-weight-bold btn-hover-text-dark"
            variant="secondary"
            v-for="asset in object[tab.key].assets"
            :key="asset.id"
            :class="'asset-' + asset.type"
            @click="downloadFile(asset)"
          >
            <span v-if="asset.type === 'youtube_iframe'">
              <i class="socicon-youtube text-danger"></i>
              <span v-html="asset.link" />
            </span>
            <span v-else-if="asset.type === 'ted_iframe'">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324 119" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#E62B1E" d="M32.678 30.831H0V.85h101.56v29.981H68.875V118.3H32.678V30.831zM107.18.85h98.75v29.981h-62.55v14.462h62.55v28.212h-62.55v14.814h62.55V118.3h-98.75V.85zm104.72 0h59.39C310.3.85 324 29.773 324 59.401c0 35.975-18.98 58.899-59.74 58.899H211.9V.85zm36.2 87.469h14.05c22.49 0 25.66-18.337 25.66-29.27 0-7.41-2.46-27.865-28.47-27.865h-11.6l.36 57.135z"/></svg>
              <span v-html="asset.link" />
            </span>
            <span v-else-if="asset.type === 'vimeo_iframe'">
              <i class="socicon-vimeo text-danger"></i>
              <span v-html="asset.link" />
            </span>
            <span v-else-if="asset.type === 'iframe'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" x="2" y="6" width="13" height="12" rx="2"/><path d="M22 8.414v7.172a1 1 0 01-1.707.707l-3.586-3.586a1 1 0 010-1.414l3.586-3.586A1 1 0 0122 8.414z" fill="#000" opacity=".3"/></g></svg>
              <span v-html="asset.link" />>
            </span>
            <span v-else-if="asset.type === 'audea_link'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="12" cy="12" r="10"/><rect fill="#000" x="11" y="7" width="2" height="8" rx="1"/><rect fill="#000" x="11" y="16" width="2" height="2" rx="1"/></g></svg>
              <span v-html="asset.link" />
            </span>
            <span v-else-if="asset.type === 'external_link'">
              <i class="fas fa-link"></i> <span v-html="asset.link" />
            </span>
            <span v-else>
              <i :class="'fas fa-file-' + asset.type"></i>
              <span v-html="asset.link" />
            </span>
          </b-button>
        </div>
        <Youtube
          v-if="Object.keys(youtube).length > 0"
          :code="youtube.content"
        />
        <Ted v-else-if="Object.keys(ted).length > 0" :code="ted.content" />
        <Vimeo
          v-else-if="Object.keys(vimeo).length > 0"
          :code="vimeo.content"
        />
        <Iframe
          v-else-if="Object.keys(iframe).length > 0"
          :code="iframe.content"
        />
        <OwnVideo
          v-else-if="Object.keys(ownVideo).length > 0"
          :code="ownVideo.content"
        />
        <ImageAsset
          v-else-if="Object.keys(image).length > 0"
          :code="image.content"
        />
      </b-tab>
    </b-tabs>
    <Html
      v-if="showHtml"
      :showAsset="showHtml"
      :code="html.content"
      @asset-closed="showHtml = false"
      :name="title"
    />
    <pdf
      :v-if="showPdf"
      :base64="showingAsset"
      :showAsset="showPdf"
      :name="pdfName"
      @asset-closed="showPdf = false"
    />
  </b-card>
</template>

<script>
import _ from "lodash";

import { BIconInfoCircleFill } from "bootstrap-vue";

import Pdf from "@/components/assets/Pdf.component";
import Youtube from "@/components/assets/Youtube.component";
import Ted from "@/components/assets/Ted.component";
import Vimeo from "@/components/assets/Vimeo.component";
import Iframe from "@/components/assets/Iframe.component";
import Html from "@/components/assets/Html.component";
import ImageAsset from "@/components/assets/ImageAsset.component";
import OwnVideo from "@/components/assets/OwnVideo.component";

import { getError } from "@/utils/helpers";

export default {
  components: {
    Pdf,
    Youtube,
    Ted,
    Vimeo,
    Iframe,
    Html,
    ImageAsset,
    OwnVideo,
    "b-icon": BIconInfoCircleFill
  },
  data() {
    return {
      tabValue: 0,
      showingAsset: "",
      showPdf: false,
      showHtml: false,
      pdfName: "Kymatio.pdf",
      chunkAt: 100,
      youtube: {},
      ted: {},
      vimeo: {},
      ownVideo: {},
      iframe: {},
      html: {},
      zip: {},
      image: {},
      link: {},
      xlsx: {},
      pdf: {},
      excel: {}
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    archetypeSvg: {
      required: false
    },
    archetypeId: {
      required: false
    },
    tabs: {
      type: Array,
      required: true
    },
    object: {
      type: Object,
      required: true
    },
    info: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    invisibleTabs() {
      return _.slice(this.computedTabs, this.chunkAt);
    },
    familyType() {
      // var creators = [92, 93, 94, 95, 96, 97, 98, 99];
      // var defenders = [100, 101, 102, 103, 104, 105, 106, 107];
      // var explorers = [76, 77, 78, 79, 80, 81, 82, 83];
      // var influencers = [84, 85, 86, 87, 88, 89, 90, 91];

      if (parseInt(this.archetypeId) >= 92 && parseInt(this.archetypeId) <= 99)
        return "creators";
      else if (
        parseInt(this.archetypeId) >= 100 &&
        parseInt(this.archetypeId) <= 107
      )
        return "defenders";
      else if (
        parseInt(this.archetypeId) >= 76 &&
        parseInt(this.archetypeId) <= 83
      )
        return "explorers";
      else if (
        parseInt(this.archetypeId) >= 84 &&
        parseInt(this.archetypeId) <= 91
      )
        return "influencers";
      else return "";
    }
  },
  methods: {
    callGetError(code, params) {
      return getError(code, params);
    },
    changeTab(index) {
      this.tabValue = index;
    },
    downloadFile(asset, automatic = true) {
      this.ted = {};
      this.youtube = {};
      this.vimeo = {};
      this.iframe = {};
      this.html = {};
      this.own_video = {};
      this.axios
        .get(asset.url)
        .then(response => {
          if (automatic) {
            if (
              response.data.records.forward &&
              asset.type !== "youtube_iframe" &&
              asset.type !== "ted_iframe" &&
              asset.type !== "vimeo_iframe" &&
              asset.type !== "excel" &&
              asset.type !== "image" &&
              asset.type !== "xlsx" &&
              asset.type !== "zip" &&
              asset.type !== "html" &&
              asset.type !== "iframe" &&
              asset.type !== "own_video"
            ) {
              let a = document.createElement("a");
              a.href = response.data.records.content;
              a.target = "_blank";
              a.click();
            } else {
              switch (asset.type) {
                case "pdf":
                  this.showingAsset = response.data.records.content;
                  this.showPdf = true;
                  break;
                case "youtube_iframe":
                  this.youtube = response.data.records;
                  this.showed = true;
                  break;
                case "ted_iframe":
                  this.ted = response.data.records;
                  this.showed = true;
                  break;
                case "vimeo_iframe":
                  this.vimeo = response.data.records;
                  this.showed = true;
                  break;
                case "excel":
                  this.excel = response.data.records;
                  this.showed = true;
                  break;
                case "image":
                  this.image = response.data.records;
                  this.showed = true;
                  break;
                case "xlsx":
                  this.xlsx = response.data.records;
                  this.showed = true;
                  break;
                case "zip":
                  this.zip = response.data.records;
                  this.showed = true;
                  break;
                case "html":
                  this.html = response.data.records;
                  this.showHtml = true;
                  break;
                case "iframe":
                  this.iframe = response.data.records;
                  this.showed = true;
                  break;
                case "own_video":
                  this.ownVideo = response.data.records;
                  this.showed = true;
                  break;
                default:
                  return response.data.records;
              }
            }
          } else {
            return response.data.records.content;
          }
        })
        .catch(error => {
          if (error.response.status === 412) {
            this.$swal(this.callGetError("file_not_found"), "", "error");
          }
        });
    }
  }
};
</script>

<style lang="scss">
#tabs-component {
  .nav {
    .nav-item {
      &.dropdown {
        margin-top: 0.4rem;
      }
      margin-top: 0.7rem;
      .nav-link {
        padding: 0.75rem 1rem;
      }
    }
  }
  .fa-file-html:before {
    content: "\f0c1";
  }
  .card-title {
    min-width: 100%;
    .card-label .archetype {
      height: 3rem;
      width: 3rem;
      padding: 0px;
      display: inline-block;
      margin-right: 5px;
      img {
        width: 3rem;
        height: 3rem;
        margin-top: 0px;
        margin-left: -1px;
      }
    }
  }
}
.asset-audea_link .svg-icon.svg-icon-primary svg g [fill] {
  fill: #005765 !important;
}
</style>
